import React, { useState } from "react"
import { Swiper, SwiperSlide, } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';

const ImageGallery = ({ images, text }) => {

  return (
   
    <div className='image--gallery p40 m-pt0 m-pb0  '>
      <div className="max-1600 ma">
        <div className="images bg-grey-100 ratio-2-1 pos-rel">
          <Slider images={images} />
        </div>
        <div className="image-caption black mt20 max-350">
          {text}
        </div>
      </div>
    </div>
   
    )
}
export default ImageGallery



function Slider({ images, ...props }) {


  const [current, setCurrent] = useState(0)
  const swiperOptions = {
    speed: 750,
    grabCursor: true,
    resistanceRatio: 0,
    resistance: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    watchSlidesProgress: true,
    modules: [Navigation, Pagination],


    onProgress: function () {

      var swiper = this;


      for (var i = 0; i < swiper.slides.length; i++) {
        var slideProgress = swiper.slides[i].progress;
        var innerOffset = swiper.width * 0.5;
        var innerTranslate = slideProgress * innerOffset;
        swiper.slides[i].querySelector(".project--slide--inner").style.transform =
          "translate3d(" + innerTranslate + "px, 0, 0)";
      }

    },
    onSlideChange: (swiper) => { setCurrent(swiper.progress); },

    onSetTranslate: function () {
      var swiper = this;
      for (var i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].querySelector(".project--slide--inner").style.transition = `0ms ease all`;
      }
    },
    onTransitionStart: function (speed) {

      var swiper = this;
      for (var i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].style.transition = speed + "ms";
        swiper.slides[i].querySelector(".project--slide--inner").style.transition =
          `750ms ease all`;
      }
    },
  }



  return (
    <>
      <Swiper className="bg-image ma h-100"  {...swiperOptions} {...props} >
        {images?.map((image, index) => (
          <SwiperSlide className="project--slide" key={index} >
            <div style={{ backgroundImage: `url(${image.url})` }} className='project--slide--inner'></div>
          </SwiperSlide>
        ))}

      </Swiper>
      <div className="indicator">
        <Circle current={current} total={images?.length} />
      </div>

      <div className="swiper-prev">
        <RightArrow />
      </div>

      <div className="swiper-next">
        <LeftArrow />
      </div>
    </>
  )
}

function LeftArrow() {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03318 21.2132L6.74881 18.9322L13.4821 12.209L0.0373804 12.209L0.0373803 8.98313L13.4608 8.98313L6.74881 2.28103L9.03316 3.91997e-05L17.3711 8.3257L17.3712 8.32563L19.6555 10.6066L9.03318 21.2132ZM15.0596 10.5796V10.6338L15.0867 10.6067L15.0596 10.5796Z" fill="white" />
    </svg>

  )
}

function RightArrow() {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7449 0.606607L13.0292 2.8876L6.29599 9.61091L19.7405 9.61091L19.7405 12.8367L6.3173 12.8367L13.0293 19.5388L10.7449 21.8198L2.407 13.4942L2.40697 13.4942L0.12261 11.2132L2.407 8.9322L10.7449 0.606607ZM4.7184 11.2402L4.7184 11.1862L4.69135 11.2132L4.7184 11.2402Z" fill="white" />
    </svg>

  )
}

function Circle({ current, total }) {

  const currentIndex = current * (total)
  const lower = 87 + (current / total)
  const upper = 150


  function getStrokeOffset(number) {

    return (upper - (((number + 1) / (total + 1)) * (upper - lower)))
  }



  return (
    <div className="pos-rel progress-wrapper  ">
      <svg className="progress-ring" width="30 " height="30 ">
        <circle className="progress-ring__circle" stroke="white" strokeWidth="1" fill="transparent" style={{ strokeDashoffset: getStrokeOffset(currentIndex), strokeDasharray: `150, 150` }} r="10" cx="15" cy="15">
        </circle>
      </svg>
      <p className='small white pos-rel'>
        {(current * (total - 1)) + 1}
      </p>
    </div>


  )

}