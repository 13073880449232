import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Spacer from './spacer'
import ConditionallyRender from './utils/conditionally-render'
import StickyLeft from './utils/sticky-left'

export default function ProjectDetails({ projectDetails }) {
    const { image, location, developer, developerLink, architectInteriorDesign, architectLink, builder, builderLink, quote, stats, projectDetailsImageTwo, link, linkText } = projectDetails
    return (
        <StickyLeft ratio='grid-230px-1fr' left={
            <SideBar comingSoon={projectDetails.comingSoon} location={location} developer={developer} developerLink={developerLink} architectInteriorDesign={architectInteriorDesign} architectLink={architectLink} builder={builder} builderLink={builderLink} link={link} linkText={linkText} />
        }>
            <div>
                <ConditionallyRender when={image?.gatsbyImageData}>
                    <div className="max-1600 ma w-100"> 
                        <div className="ratio-2-1 pos-rel">
                            <GatsbyImage alt='' className='bg-image' image={image?.gatsbyImageData} />
                        </div>
                    </div>
                    <Spacer className='m-h-60' />
                    <Spacer className='m-hide  m-h-60 ' />
                </ConditionallyRender>

                <ConditionallyRender when={(projectDetailsImageTwo || (quote.name && quote.content) || stats.length > 0)}>
                    <ProjectInfo projectDetailsImageTwo={projectDetailsImageTwo} stats={stats} quote={quote} image={projectDetailsImageTwo} />
                </ConditionallyRender>

            </div>
        </StickyLeft>
    )
}

function SideBar({ location, comingSoon, developer, developerLink, architectInteriorDesign, architectLink, builder, builderLink, link, linkText }) {
    return <div className='small-cap black max-w-75'>
        <ConditionallyRender when={location}>
            <div className="mb20">
                <h5 className='small-cap lower mt0 mb0'>Location</h5>
                <h5 className='small-cap lower mt0 mb0'>{location}</h5>
            </div>
        </ConditionallyRender>
        <ConditionallyRender when={developer}>
            <div className="mb20">
                <h5 className='small-cap lower mt0 mb0'>Developer</h5>
                {(developerLink ? 
                    (<a className='ul-link link black small-cap lower ' href={developerLink} rel="noreferrer" target="_blank">{developer}</a>): 
                    (<h5 className='small-cap lower mt0 mb0'>{developer}</h5>)
                )}
            </div>
        </ConditionallyRender>
        <ConditionallyRender when={architectInteriorDesign}>
            <div className="mb20">
                <h5 className='small-cap lower mt0 mb0'>Architect</h5>
                {(architectLink ? 
                    (<a className='ul-link link black small-cap lower ' href={architectLink} rel="noreferrer" target="_blank">{architectInteriorDesign}</a>): 
                    (<h5 className='small-cap lower mt0 mb0'>{architectInteriorDesign}</h5>) 
                )}
            </div>
        </ConditionallyRender>
        <ConditionallyRender when={builder}>
            <div className="mb20">
                <h5 className='small-cap lower mt0 mb0'>Builder</h5>
                {(builderLink ? 
                    (<a className='ul-link link black small-cap lower ' href={builderLink} rel="noreferrer" target="_blank">{builder}</a>): 
                    (<h5 className='small-cap lower mt0 mb0'>{builder}</h5>) 
                )}
                
            </div>
        </ConditionallyRender>
        <ConditionallyRender when={link && linkText}>
            <div className="">
                <a className='read--more link small-cap lower' href={link} rel="noreferrer" target="_blank">
                    <span />
                    {linkText}
                </a>
            </div>
        </ConditionallyRender>
        <ConditionallyRender when={comingSoon}>
            <div className="coming-soon">
                <p className='read--more m0 link small-cap lower'> <span />{comingSoon}</p>
            </div>
        </ConditionallyRender>
        <Spacer className='m-h-60' />
    </div>
}

function ProjectInfo({ image, quote, stats }) {
    return (
        <ConditionallyRender when={quote?.name || quote?.content || stats?.length > 0 || image?.gatsbyImageData}>
            <div className='grid grid-1-33 gap-x-10p m-flex m-flex-col max-1600 ma'>
                <div className='flex justify-between flex-col  '>
                    <ConditionallyRender when={quote?.name && quote.content}>
                        <p className='black mt0 mb0'>
                            <span className="bolded--capital" dangerouslySetInnerHTML={{ __html: quote.content }}></span>
                            <br /><br />
                            {quote.name}
                        </p>
                    </ConditionallyRender>
                  
                    <ConditionallyRender when={image?.gatsbyImageData}>
                        <Spacer className='m-show m-h-60'/>
                        <div className='max-650 m-show'>
                            <GatsbyImage alt='' className='ratio-2-1' image={image?.gatsbyImageData} />
                        </div>
                    </ConditionallyRender>
                    <ConditionallyRender when={stats.length > 0}>
                        <Spacer className='m-show m-h-60'/>
                        <Table stats={stats} />
                    </ConditionallyRender>

                </div>
                <ConditionallyRender when={image?.gatsbyImageData}>
                    <div className='max-650 w-100 m-hide mla'>
                        <div className="ratio-1-2 pos-rel">
                            <GatsbyImage className='bg-image' image={image?.gatsbyImageData} />
                        </div>
                    </div>
                </ConditionallyRender>

            </div>
        </ConditionallyRender>
    )
}

function TableRow({ name, value }) {
    return (
        <div className='pt20 pb20 bt1'>
            <h5 className='lower mt0 mb0 text-grey small uppercase small-cap mb5'>{name.toUpperCase()}</h5>
            <h5 className='h3 mt0 mb0 black book'>{value}</h5>
        </div>
    )
}

function Table({ stats }) {

    return (
        <div className='grid grid-1 gap-y-0'>
            {stats.map(({ name, value },index) => {
                return <TableRow key={index} name={name} value={value} />
            })}
        </div>
    )
}