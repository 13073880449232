import * as React from "react"

const Paragraph = ({text}) => (
  <div className='text--paragraph p40 black pt0'>
    <div className="bt1">  
      <p className="m0 uppercase book small pt20">About</p>
      <p className="h3 m0 mt40">{text}</p> 
    </div>
  </div> 
) 
export default Paragraph
