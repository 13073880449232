import { GatsbyImage } from 'gatsby-plugin-image'
import { sortBy } from 'lodash'
import React, { useMemo } from 'react'
import Spacer from '../components/spacer'
import useGetProjectLink from '../hooks/projects-link'
import Link from './link'
export default function OtherProjects({ projects }) {
	const sortedProjects = useSortProjectsByYear(projects)
	const [firstProject] = sortedProjects

	return (
		<div className='p4020'>
			<div className='mb40 mt0 bt1 pt20'>
				<h1 className='small mt0 mb0'>PROJECTS </h1>
			</div>

			<div className='more-project-grid mt40'>
				{sortedProjects.map(project => (
					<Project project={project} key={project.id} />
				))}
			</div>
		</div>
	)
}

const Project = ({ project }) => (
	<Link className='link flex' to={'/projects/' + project.slug}>
		<div className='project-card w-100 flex flex-col'>
			<div className='ratio-2-1 pos-rel overflow bg-grey'>
				<GatsbyImage className='bg-image image--zoom' image={project.heroImage.gatsbyImageData} alt='Project Image' />
			</div>
			<div className='info'>
				<p className='uppercase book m0 mt10'>{project.name}</p>
				<p className='signifier listing-content mt5 balance' dangerouslySetInnerHTML={{ __html: project.heroCaption }} />
			</div>
		</div>
	</Link>
)

function TableRow({ project, index, active, ...props }) {
	const projectLink = useGetProjectLink(project)
	return (
		<Link to={projectLink} {...props} className={'grid other-projects-grid bb1 link  ' + (active ? 'active' : '') + (index === 0 ? ' bt1' : '')}>
			<div>{project.year}</div>
			<div>{project.name}</div>
			<div className='arrow--hover'></div>
		</Link>
	)
}

function ProjectThumbnail({ projects, currentProject }) {
	return (
		<>
			<div className=' pr30  m-pr0 m-hide  '>
				<div className='pos-rel h100 ratio-1-125'>
					{projects.map((project, index) => {
						return (
							<div key={index} className={'bg-image project-thumnail-image ' + (isActive(project, currentProject) ? 'active' : '')}>
								<GatsbyImage alt='Project Image' objectFit='cover' className={'h100  '} image={project.heroImage?.gatsbyImageData} />
							</div>
						)
					})}
				</div>
			</div>
			<Spacer className='m-hide' />
		</>
	)
}

function isActive(project, currentProject) {
	return project.id === currentProject
}

function useSortProjectsByYear(projects) {
	return useMemo(() => {
		return sortBy(projects, 'year').reverse()
	}, [projects])
}
